export const initialProductLine = {
  description: 'Item description',
  quantity: '1',
  rate: '0.00',
};

export const initialInvoice = {
  logo: '',
  client: '',
  brand: '',
  title: 'INVOICE',
  name: '',
  brandName: '',
  brandAddress: '',
  brandCity: '',
  brandState: '',
  brandZip: '',
  brandCountry: '',
  clientBusinessName: '',
  clientName: '',
  clientAddress: '',
  clientCity: '',
  clientZip: '',
  clientCountry: '',
  clientEmail: '',
  merchant: '',
  invoiceTitle: '',
  invoiceNumber: '',
  invoiceDate: '',
  invoiceDueDate: '',
  invoiceBgColor: '',
  textColorPrimary: '',
  textColorSecondary: '',
  productLines: [{ ...initialProductLine }],
  taxPercent: '10',
  taxAmount: 0,
  subTotal: 0,
  totalDue: 0,
  currency: '$',
  notes: 'It was great doing business with you.',
  terms: 'Please make the payment by the due date.',
};
